<template>
    <div class="animated fadeIn">
        <b-card class="card-border mt-4">
            <b-card-title><i class="fa fa-check-circle"></i> Inventory Compliance Report</b-card-title>
            <b-card-sub-title>Summary of a company's compliance on inventory sessions</b-card-sub-title>
            <div fluid class="px-2 mt-4">
                <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

                <!-- Filter  -->
                <b-row class="my-2">
                    <b-col sm="12" md="3" lg="3">
                        <b-button v-b-popover.hover.right="'Toggle to show/hide filter options'" v-b-toggle.collapse-1
                            class="filter">
                            FILTER OPTIONS
                        </b-button>
                    </b-col>
                    <b-col sm="12">
                        <!-- Collapsible Filter Options -->
                        <b-collapse id="collapse-1" class="mt-2" visible>
                            <b-card>
                                <b-row no-gutters>
                                    <b-col lg="4" md="10" sm="12" class="mr-4">
                                        <b-form-group label="Date From">
                                            <b-form-datepicker name="Date From" v-model="filterBy.dateFrom" locale="en"
                                                reset-button label-reset-button="Clear" :date-format-options="{
                                                    year: 'numeric',
                                                    month: 'short',
                                                    day: '2-digit',
                                                    weekday: 'short',
                                                }" :date-disabled-fn="dateFromDisabled" v-validate="'required'" />
                                            <span v-show="errors.has('Date From')" class="help-block">
                                                {{ errors.first('Date From') }}
                                            </span>
                                        </b-form-group>
                                    </b-col>
                                    <b-col lg="4" md="10" sm="12" class="mr-4">
                                        <b-form-group label="Date To">
                                            <b-form-datepicker name="Date To" v-model="filterBy.dateTo" locale="en"
                                                reset-button label-reset-button="Clear" :date-format-options="{
                                                    year: 'numeric',
                                                    month: 'short',
                                                    day: '2-digit',
                                                    weekday: 'short',
                                                }" :date-disabled-fn="dateFromDisabled" v-validate="'required'" />
                                            <span v-show="errors.has('Date To')" class="help-block">
                                                {{ errors.first('Date To') }}
                                            </span>
                                        </b-form-group>
                                    </b-col>

                                    <b-col lg="4" md="10" sm="12" class="mr-4">
                                        <b-form-group label="Company">
                                            <v-select name="Company" class="style-chooser" label="text"
                                                placeholder=" - Please select - "
                                                :options="filterByOptions.companyItems"
                                                :reduce="(company) => company.value" v-model="filterBy.company"
                                                v-validate="'selectRequired'">
                                                <template v-slot:no-options="{ search, searching }">
                                                    <template v-if="searching">
                                                        No results found for
                                                        <em>
                                                            <strong>{{ search }}</strong>
                                                        </em>
                                                    </template>
                                                    <em :style="{ opacity: 0.5 }" v-else>
                                                        Start typing to search for a company
                                                    </em>
                                                </template>
                                            </v-select>
                                            <span v-show="errors.has('Company')" class="help-block">
                                                {{ errors.first('Company') }}
                                            </span>
                                        </b-form-group>
                                    </b-col>
                                    <b-col lg="3" md="3" sm="12" class="mr-4">
                                        <b-form-group label="Region">
                                            <v-select name="Region" class="style-chooser" label="text"
                                                :options="filterByOptions.regionItems" :reduce="(region) => region.value"
                                                v-model="filterBy.region" v-validate="'selectRequired'">
                                                <template v-slot:no-options="{ search, searching }">
                                                    <template v-if="searching">
                                                        No results found for
                                                        <em>
                                                            <strong>{{ search }}</strong>
                                                        </em>
                                                    </template>
                                                    <em :style="{ opacity: 0.5 }" v-else>
                                                        Start typing to search for a region
                                                    </em>
                                                </template>
                                            </v-select>
                                            <span v-show="errors.has('Region')" class="help-block">
                                                {{ errors.first('Region') }}
                                            </span>
                                        </b-form-group>
                                    </b-col>

                                    <b-col lg="3" md="3" sm="12" class="mr-2">
                                        <b-form-group label="Area">
                                            <v-select name="Area" class="style-chooser" label="text"
                                                :options="filterByOptions.areaItems" :reduce="(area) => area.value"
                                                v-model="filterBy.area">
                                                <template v-slot:no-options="{ search, searching }">
                                                    <template v-if="searching">
                                                        No results found for
                                                        <em>
                                                            <strong>{{ search }}</strong>
                                                        </em>
                                                    </template>
                                                    <em :style="{ opacity: 0.5 }" v-else>
                                                        Start typing to search for an area
                                                    </em>
                                                </template>
                                            </v-select>
                                            <span v-show="errors.has('Area')" class="help-block">
                                                {{ errors.first('Area') }}
                                            </span>
                                        </b-form-group>
                                    </b-col>

                                    <b-col lg="4" md="6" sm="12" class="mr-4">
                                        <b-form-group label="Compliant">
                                            <v-select class="style-chooser" label="text"
                                                placeholder=" - Please select - "
                                                :options="filterByOptions.compliantItems"
                                                :reduce="(compliant) => compliant.value" v-model="filterBy.compliant">
                                                <template v-slot:no-options="{ search, searching }">
                                                    <template v-if="searching">
                                                        No results found for
                                                        <em>
                                                            <strong>{{ search }}</strong>
                                                        </em>
                                                    </template>
                                                    <em :style="{ opacity: 0.5 }" v-else>
                                                        Start typing to search for status
                                                    </em>
                                                </template>
                                            </v-select>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row no-gutters>
                                    <b-col sm="12">
                                        <b-button class="mr-1" variant="success" @click="retrieveData">
                                            Generate
                                        </b-button>
                                        <b-button class="mr-1" variant="primary" @click="resetFilters">
                                            Reset
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-collapse>
                    </b-col>
                </b-row>

                <div v-if="items.length > 0">
                    <div class="btn-table-options">
                        <b-row>
                            <b-col sm="6" md="3">
                                <b-dropdown text=" Select Actions " variant="dark" slot="append">
                                    <b-dropdown-item>
                                        <json-excel :data="exportData" :fields="exportFields" type="xls"
                                            :name="fileName + '.xls'">Export Report in Excel</json-excel>
                                    </b-dropdown-item>
                                    <b-dropdown-item>
                                        <json-excel :data="exportData" :fields="exportFields" type="csv"
                                            :name="fileName + '.csv'">Export Report to CSV</json-excel>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-col>
                            <b-col sm="6" md="3" offset-md="6" class="my-1 text-md-right">
                                <b-input-group prepend="Show" append="/ Page">
                                    <b-form-select :options="pageOptions" v-model="perPage" />
                                </b-input-group>
                            </b-col>
                        </b-row>
                    </div>

                    <b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
                        :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection" responsive>
                        <template v-slot:cell(description)="row">
                            <span class="truncate-text">{{ row.item.description }}</span>
                        </template>
                        <template v-slot:cell(compliant)="row">
                            <span v-if="row.item.compliant === 'YES'">
                                <b-badge variant="success">{{ row.item.compliant }}</b-badge>
                            </span>
                            <span v-else>
                                <b-badge variant="danger">{{ row.item.compliant }}</b-badge>
                            </span>
                        </template>

                        <template v-slot:cell(totalSessions)="row">
                            <span class="numFont">
                                {{ row.item.totalSessions }}
                            </span>
                        </template>

                        <template v-slot:cell(totalScannedAssets)="row">
                            <span class="numFont">
                                {{ row.item.totalScannedAssets.toLocaleString("en-US") }}
                            </span>
                        </template>

                        <template v-slot:cell(totalAssetsAtHand)="row">
                            <span class="numFont">
                                {{ row.item.totalAssetsAtHand.toLocaleString("en-US") }}
                            </span>
                        </template>

                        <template v-slot:cell(sessionIds)="row">
                            <truncate type="html" action-class="text-primary"
                                :text="`<p style='margin-bottom: 0px;'><i>${row.item.sessionIds}</i></p>`" clamp="Show More"
                                less="Show Less" :length="50" />
                        </template>

                    </b-table>
                    <b-row>
                        <b-col md="8" sm="12" class="my-1">
                            <span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
                        </b-col>
                        <b-col md="4" sm="12" class="my-1">
                            <b-pagination align="right" :total-rows="totalRows" :per-page="perPage"
                                v-model="currentPage" class="my-0" />
                        </b-col>
                    </b-row>
                </div>
                <div v-else>
                    <b-card> No Results Found. </b-card>
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';

// API
import reportApi from '@/api/reportApi';

// Others
import config from '@/config/env-constants';
import JsonExcel from 'vue-json-excel';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment';
import truncate from 'vue-truncate-collapsed';
import _ from 'lodash';

export default {
    name: 'inventory-compliance-report',
    components: { JsonExcel, Loading, truncate },
    data() {
        return {
            items: [],
            fields: [],
            currentPage: 1,
            perPage: 10,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,

            defaultFilterBy: {
                dateFrom: null,
                dateTo: null,
                company: { ...config.companyDefaultValue },
                region: { ...config.dropdownDefaultValue },
                area: { ...config.dropdownDefaultValue },
                compliant: null
            },
            filterBy: {
                dateFrom: null,
                dateTo: null,
                company: { ...config.companyDefaultValue },
                region: { ...config.dropdownDefaultValue },
                area: { ...config.dropdownDefaultValue },
                compliant: null
            },
            prevFilterBy: null,

            filterByOptions: {
                regionItems: [],
                areaItems: [],
                companyItems: [],
                compliantItems: [
                    { text: ' - Please select - ', value: null },
                    { text: 'YES', value: 'YES' },
                    { text: 'NO', value: 'NO' },
                ],
            },

            allRegionsObj: {},
            allAreasObj: {},
            allCompaniesObj: {},

            isSuperAdmin: this.$store.getters.isSuperAdmin,
            loggedUser: this.$store.getters.loggedUser,
            loggedUserCompany: this.$store.getters.loggedUserCompany,
            // Check for loader
            isLoading: false,
        }
    },
    computed: {
        dateFrom() {
            const dateTo = moment();
            const dateFrom = dateTo.add(-30, 'days');
            return dateFrom.format('YYYY-MM-DD');
        },
        dateTo() {
            return moment().format('YYYY-MM-DD');
        },

        /**
         * Returns the set of data to be included in the export. For now this just
         * returns the data as is.
         *
         * @returns {Array} the set of data to be included in the export.
         */
        exportData() {
            return this.items;
        },

        /**
         * Derives the field information based from the data table configuration.
         *
         * @returns {object} the fields to be included in the export.
         */
        exportFields() {
            return {
                Company: 'company',
                Description: 'description',
                Region: 'region',
                Area: 'area',
                'Branch Code': 'branchCode',
                Compliant: 'compliant',
                'Total Sessions': 'totalSessions',
                'Session Ids': 'sessionIds',
                'Last User Scanned': 'lastUserScanned',
                'Total Scanned Assets': 'totalScannedAssets',
                'Total Assets At Hand': 'totalAssetsAtHand'
            };
        },

        fileName() {
            let currTimeStamp = DateUtil.getCurrentTimestamp();
            return 'InventoryComplianceReport-' + DateUtil.getDateInMMDDYYYYFormat(currTimeStamp);
        },
    },

    watch: {
        "filterBy.region": function () {
            if (this.filterBy.region !== null) {
                let filteredAreasObj = _.filter(this.allAreasObj, o => {
                    return o.region === this.filterBy.region;
                });
                this.filterByOptions.areaItems = DropDownItemsUtil.retrieveAreas(filteredAreasObj);
            } else {
                this.filterByOptions.areaItems = DropDownItemsUtil.retrieveAreas(this.allAreasObj);
            }

            // reset area
            this.filterBy.area = null;
        }
    },

    mounted() {
        setTimeout(async () => {
            try {
                // Filter Access
                if (this.$store.getters.isScanner
                    || this.$store.getters.isAccounting
                    || this.$store.getters.isApprover
                    || this.$store.getters.isMaintenance) {
                    this.$router.push('/dashboard');
                    this.$toaster.warning('You are not allowed to access this page.');
                }

                // show loading indicator
                this.isLoading = true;

                this.allRegionsObj = this.$store.getters.regions;
                this.filterByOptions.regionItems = DropDownItemsUtil.retrieveRegions(this.allRegionsObj);

                this.allAreasObj = this.$store.getters.areas;
                this.filterByOptions.areaItems = DropDownItemsUtil.retrieveAreas(this.allAreasObj);

                this.allCompaniesObj = this.$store.getters.companies;

                // Set Default Filter Options
                this.defaultFilterBy.company = DropDownItemsUtil.getCompanyItem(this.loggedUserCompany);
                this.defaultFilterBy.dateFrom = this.dateFrom;
                this.defaultFilterBy.dateTo = this.dateTo;
                await this.resetFilters();

            } catch (_error) {
                this.$toaster.error('Error loading data. Please reload the page again.');
            } finally {
                // hide loading indicator
                this.isLoading = false;
            }
        }, config.timeout);
    },
    methods: {
        async resetFilters() {
            this.items = [];
            this.filterBy = { ...this.defaultFilterBy };

            // reset validation
            this.$validator.reset();
            this.errors.clear();

            await this.retrieveData();
        },

        dateFromDisabled(_ymd, date) {
            return date > new Date();
        },
        async validateFilter() {
            let isValid = await this.$validator.validateAll();

            if (!isValid) {
                this.$toaster.warning('Please address the field/s with invalid input.');
                return isValid;
            }

            if ((_.isEmpty(this.filterBy.dateFrom) && !_.isEmpty(this.filterBy.dateTo))
                || (!_.isEmpty(this.filterBy.dateFrom) && _.isEmpty(this.filterBy.dateTo))) {
                this.$toaster.warning('Invalid Date Range. Date From and Date To must both have value.');
                isValid = false;
            } else if (this.filterBy.dateFrom > this.filterBy.dateTo) {
                this.$toaster.warning('Invalid Date Range. Date From must be less than Date To.');
                isValid = false;
            } else if (DateUtil.getNoOfDays(this.filterBy.dateFrom, this.filterBy.dateTo) > 100) {
                this.$toaster.warning(
                    'Invalid Date Range. Data range is allowed up to 100 days difference.'
                );
                isValid = false;
            }

            return isValid;
        },
        getReportParameters() {
            let filter = { ...this.filterBy };
            filter.companyId = this.loggedUserCompany.id;

            if (this.filterBy.dateFrom) {
                filter.fromTimestamp = DateUtil.startDateTimeStamp(
                    new Date(this.filterBy.dateFrom)
                );
            }
            if (this.filterBy.dateTo) {
                filter.toTimestamp = DateUtil.endDateTimeStamp(
                    new Date(this.filterBy.dateTo)
                );
            }

            return filter;
        },
        async retrieveData() {
            let isValid = await this.validateFilter();
            if (!isValid) {
                return;
            }

            // Reset items
            this.items = [];
            // Show loader
            this.isLoading = true;

            try {
                let view = this.isSuperAdmin ? config.view.ADMIN : config.view.COMPANY;
                let reportParam = this.getReportParameters();
                let { data } = await reportApi.getInventoryComplianceReport(
                    reportParam,
                    view,
                    this.loggedUser.id
                );

                if (data.isSuccess) {
                    let items = data.items;

                    if (_.isEmpty(items)) {
                        this.$toaster.warning('No Results found.');
                    } else {
                        this.items = items;
                        this.totalRows = this.items.length;
                    }

                    // Mark the prevFilter
                    this.prevFilter = { ...this.filterBy };
                } else {
                    this.$toaster.error('Error loading data. Please reload the page again.');
                }
            } catch (_error) {
                this.$toaster.error('Error loading data. Please reload the page again.');
            } finally {
                // hide loading indicator
                this.isLoading = false;
            }
        },
    }

}
</script>